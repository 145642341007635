import React from "react";
import s from './Valores.module.scss';
import { useStaticQuery, graphql } from "gatsby";
import CenterMode from'./CenterMode';


const Valores = () => {
    const valoreses = useStaticQuery(graphql`{
        gcms {
            valoreses (where: {status: PUBLISHED}){
                icono{
                    url
              	}
              	title
              	description {
              	  text
              	}
            }
        }
    }`).gcms.valoreses;

    return (
        <div className={s.container}>
            <p className={s.title}>Nuestros valores</p>
            <div className={s.valoresContainer}>
                        <CenterMode medios={true}>
                            {valoreses.map((item, subindex) => {
                                    return (
                                        
                                           
                                            <div key={`${subindex}_mediaitem`} className={s.divValores}>
                                                <div  className={s.imgWraper}>
                                                    <img className={s.imgSlider}
                                                    alt={"iconos de valores"}
                                                    src={item.icono.url}
                                                    />
                                                </div>
                                                
                                                <p className={s.tituloValor}>{item.title}</p>
                                                <p className={s.description}>{item.description.text}</p>
                                            </div>
                                     
                                   
                                        
                                    );
                                })}
                        </CenterMode>
                      
               
            </div>
        </div>
    )
}

export default Valores
