import React from 'react';
import s from './Button.module.scss';

const JobButton = ({ children, style, className,onClick, name, disabled, icon, alt }) => {

    return (
        <button className={`${(className) ? className : s.CTA}`} style={style} onClick={onClick} name={name} disabled={disabled}>
            {(icon) && <img src={icon} className={s.icon} alt={alt}/>}
            {children}
        </button>
    )
};

export default JobButton;
