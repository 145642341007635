import React from "react";
import { Link } from "gatsby";
import s from './JobItem.module.scss';
import rightArrow from '../images/icons/right-arrow-green.svg';


const JobItem = ({ title, icon, vacancy, index, link }) => {

    return(
        <Link to={`/vacantes#${link}`} className={s.linkContainer}>
            <div className={s.itemCard} key={index}>
            <div className={s.iconWraper}>
                <img className={s.jobIcon} src={icon} alt={`Icono de ${link}`}/>
            </div>
            <div className={s.textContainer}>
                <p className={s.title}>{title}</p>
                <div className={s.vacancyContainer}>
                    <p className={s.vacancy}>{vacancy}</p>
                    <img className={s.rightArrow} src={rightArrow} alt='Flecha derecha verde'/>
                </div>
            </div>
        </div>
        </Link>
    )
}

export default JobItem
