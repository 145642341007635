import React from "react";
import "../style/style.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import s from '../style/jobsPage.module.scss';
import {isMobile} from 'react-device-detect';
import Header from '../components/Header';
import Valores from '../components/Valores';
import { TagManager, TagManagerFooter } from '../components/TagManager';
import MetaData from '../components/MetaData';
import PageView from '../components/PageView';
import Footer from '../components/Footer';
import { DESKTOP, LAPTOP, MOBILE } from '../config.js';
import JobItems from '../components/JobItems';
import SendCv from '../components/SendCv';


const JobsPage = () => {
    const productType = 'Jobs';
    const data = useStaticQuery(graphql` {
    gcms {
            headerInfo: headers (where: {status: PUBLISHED, landingType: Jobs}){
                landingType
                title
            }
        },
        desktop: file(relativePath: { eq: "JobsPage.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 3840) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
        laptop: file(relativePath: { eq: "Jobs-small.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
        mobile: file(relativePath: { eq: "Jobs-mobile.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }`);

    const headers = data.gcms;
    let imageDataSrc = [
        {
            ...data.mobile.childImageSharp.fluid,
            media: MOBILE,
        },
    ];
    if (!isMobile) {
        imageDataSrc = [
            {
                ...data.desktop.childImageSharp.fluid,
                media: DESKTOP,
            },
            {
                ...data.laptop.childImageSharp.fluid,
                media: LAPTOP,
            },
        ];
    }
    return(
      <div>
            <MetaData/>
            <TagManager/>
            <PageView/>
            <Header title={headers.headerInfo[0].title} className={s.headerContainer} backgrounds={imageDataSrc} jobs={true} subLanding={true} productType={productType}></Header>
            <Valores/>
            <JobItems/>
            <SendCv/>
            <Footer jobs={true}/>
            <TagManagerFooter/>
      </div>
    )
}

export default JobsPage
