import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import s from './JobItems.module.scss';
import JobItem from './JobItem';
import JobButton from './JobButton';


const JobItems = () => {
    const data = useStaticQuery(graphql`{
        gcms {
            rubrosJobses (where: {status: PUBLISHED}) {
                title
                vacancy
                icon{
                    url
                }
                link
            }
        }
    }`);

    const rubrosJobses = data.gcms.rubrosJobses;

    return(
        <div className={s.jobItemsContainer}>
            <p className={s.title}>¿Qué es lo que te gusta?</p>
            <div className={s.itemsWraper}>
                {rubrosJobses.map((item, index) =>
                    <JobItem title={item.title} icon={item.icon.url} link={item.link} vacancy={item.vacancy} key={index}/>
                )}
            </div>
            <Link to='/vacantes' className={s.buttonWrapper}>
                <JobButton  className={s.vacancyButton}>
                    Ver todas las vacantes 
                </JobButton>
            </Link>
        </div>
    )
}

export default JobItems
